import React from "react";
import { Redirect, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

export function ContentRoute({ children, component, render, ...props }) {
  const { isAuthorized, parentId, childrenId } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      parentId: auth.parentId,
      childrenId: auth.childrenId,
    }),
    shallowEqual
  );

  return (
    <Route
      {...props}
      render={(routeProps) => {
        if (isAuthorized && parentId && childrenId) {
          if (typeof children === "function") {
            return <>{children(routeProps)}</>;
          }

          if (!routeProps.match) {
            return null;
          }

          if (children) {
            return <>{children}</>;
          }

          if (component) {
            return <>{React.createElement(component, routeProps)}</>;
          }

          if (render) {
            return <>{render(routeProps)}</>;
          }

          return null;
        } else {
          if (!isAuthorized) {
            return <Redirect to="/auth/login" />;
          } else if (!parentId || !childrenId) {
            return <Redirect to="/select-account" />;
          }
        }
      }}></Route>
  );
}
