import React, { lazy, Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "./Context/LayoutContext";
import { ContentRoute } from "./components/ContentRoute/ContentRoute";
import { useSelector, shallowEqual } from "react-redux";
import Layout from "./hoc/Layout";

const Dashboard = lazy(() => import("./modules/Dashboard"));
const OrdersRouter = lazy(() => import("./modules/Orders/pages/OrdersRouter"));
const Notification = lazy(() => import("./modules/Notification/pages"));
const ReportsRouter = lazy(() => import("./modules/Reports/pages/ReportsRouter"));
const ProductsRouter = lazy(() => import("./modules/Products/pages/ProductsRouter"));
const SettingsRouter = lazy(() => import("./modules/Settings/SettingsRouter"));
const PersonalDetail = lazy(() => import("./modules/Profile/pages"));
const ShipmentsRouter = lazy(() => import("./modules/Shipments/pages/ShipmentsRouter"));

export default function BasePage() {

  const { complianceRules } = useSelector(
    (state) => ({
      complianceRules: state?.auth?.user?.ProfilePreferences?.complianceRules
    }),
    shallowEqual
  );

  return (
    <Layout>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          <ContentRoute path="/dashboard" component={Dashboard} />
          <ContentRoute path="/shipments" component={ShipmentsRouter} />
          {complianceRules && <ContentRoute path="/orders" component={OrdersRouter} />}
          {complianceRules && <ContentRoute path="/products" component={ProductsRouter} />}
          <ContentRoute path="/reports" component={ReportsRouter} />
          <ContentRoute path="/profile" component={PersonalDetail} />
          <ContentRoute path="/notification" component={Notification} />
          <ContentRoute path="/settings" component={SettingsRouter} />
          {/* Redirect from any route to /dashboard (in case user type manual URL in browser)  */}
          <Redirect from="/" to="/dashboard" />
        </Switch>
      </Suspense>
    </Layout>
  );
}
