import axios from "axios";
export const LOGIN_URL = `${process.env.REACT_APP_ACCOUNT_SERVICE_API_URL_V1}/users/login`;
export const REGISTER_URL = `${process.env.REACT_APP_ACCOUNT_SERVICE_API_URL}/auth/register`;
export const ACCOUNTS_URL = `${process.env.REACT_APP_ACCOUNT_SERVICE_API_URL}/login`;
export const REQUEST_PASSWORD_URL = `${process.env.REACT_APP_ACCOUNT_SERVICE_API_URL}/auth/forgotpassword`;
export const RESET_PASSWORD_URL = `${process.env.REACT_APP_ACCOUNT_SERVICE_API_URL}/auth/resetPassword`;
export const VERIFY_TOKEN_URL = `${process.env.REACT_APP_ACCOUNT_SERVICE_API_URL}/auth/verifyToken`;
export const ME_URL = `${process.env.REACT_APP_ACCOUNT_SERVICE_API_URL_V2}/users/me`;
export const VERIFY_OTP = `${process.env.REACT_APP_ACCOUNT_SERVICE_API_URL}/auth/verify`;
export const RESEND_VERIFY_OTP = `${process.env.REACT_APP_ACCOUNT_SERVICE_API_URL}/auth/verification/resend`;

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

//THIS IS REGISTER FUNCTION FOR USER AUTH LOGING
export function register(
  email,
  first_name,
  last_name,
  password,
  date_of_birth,
  phone_number
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name,
    last_name,
    password,
    date_of_birth,
    phone_number,
  });
}

//THIS IS REGISTER FUNCTION FOR USER AUTH LOGING
export function saveAccount(parentId, childId) {
  return axios.post(
    `${process.env.REACT_APP_ACCOUNT_SERVICE_API_URL_V2}/users/defaultAccounts`,
    {
      parentId,
      childId,
    }
  );
}

export function resetPasswordRequest({ token, uid, password }) {
  return axios.put(
    `${RESET_PASSWORD_URL}?token=${token}&uid=${uid}&newPassword=${password}`
  );
}

export function verifyToken(token) {
  return axios.get(`${VERIFY_TOKEN_URL}?token=${token}`);
}

export function getSelectedAccount(accountId) {
  return axios.get(
    `${process.env.REACT_APP_ACCOUNT_SERVICE_API_URL_V2}/accounts/${accountId}`
  );
}

export function getEnterprisesAccounts() {
  return axios.get(
    `${process.env.REACT_APP_ACCOUNT_SERVICE_API_URL_V1}/users/access/enterprises`
  );
}

export function getChildrenAccounts(parentId) {
  return axios.get(
    `${process.env.REACT_APP_ACCOUNT_SERVICE_API_URL_V1}/users/access/accounts/${parentId}`
  );
}

export function resendToken(email) {
  return axios.post(`${RESEND_VERIFY_OTP}`, { email: email });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function verifyOTP(email, token) {
  return axios.post(VERIFY_OTP, { email, token });
}

export function verifyEmail(email) {
  return axios.post(`${process.env.REACT_APP_ACCOUNT_SERVICE_API_URL_V2}/users/forgot-password`, { email });
}

export function changePassword(payload) {
  return axios.post(`${process.env.REACT_APP_ACCOUNT_SERVICE_API_URL_V1}/users/set-password`, payload);
}

export function resendNewLink(payload) {
  return axios.post(`${process.env.REACT_APP_ACCOUNT_SERVICE_API_URL_V1}/users/resend-email`, payload);
}