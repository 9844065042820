import React from "react";
import { Route } from "react-router-dom";

export function UnAutorizedContentRoute({
    children,
    component,
    render,
    ...props
}) {
    return (
        <Route {...props}>
            {(routeProps) => {
                if (typeof children === "function") {
                    return <>{children(routeProps)}</>;
                }

                if (!routeProps.match) {
                    return null;
                }

                if (children) {
                    return <>{children}</>;
                }

                if (component) {
                    return <>{React.createElement(component, routeProps)}</>;
                }

                if (render) {
                    return <>{render(routeProps)}</>;
                }

                return null;
            }}
        </Route>
    );
}
