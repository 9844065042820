import { useEffect } from "react";
import useIsFirstMount from "../../hooks/useIsFirstMount";

const getFieldErrorNames = formikErrors => {
  const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
    Object.keys(obj).forEach(key => {
      const value = obj[key]
      if (!value) return
      const nextKey = prefix ? `${prefix}.${key}` : key
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result)
      } else {
        result.push(nextKey)
      }
    })
    return result
  }
  return transformObjectToDotNotation(formikErrors)
}

const FormikErrorScrollToView = ({ formik }) => {
  const isFirstMount = useIsFirstMount();

  useEffect(() => {
    if (isFirstMount || formik.isSubmitting) return;
    if (Object.keys(formik.errors)?.length) {
      const fieldErrorNames = getFieldErrorNames(formik.errors)
      if (fieldErrorNames.length <= 0) return
      const element = document.querySelector(`input[name='${fieldErrorNames[0]}']`)
      if (!element) return
      if (element.type === "hidden") {
        element.type = "text";
        element.scrollIntoView({ behavior: "smooth", block: "center" })
        element.type = "hidden";
      } else {
        element.scrollIntoView({ behavior: "smooth", block: "center" })
      }
    }
  }, [formik.isSubmitting]);

  return null;
}

export default FormikErrorScrollToView;
