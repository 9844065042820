import React, { useEffect, useState } from "react";
import { getEnterprisesAccounts, getChildrenAccounts } from "../_redux/authCrud";
import { useFormik, FormikProvider, Field } from "formik";
import { ReactSelect } from "../../../components/ReactSelect/ReactSelect";
import { saveAccount } from "../_redux/authCrud";
import { useHistory } from "react-router-dom";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Loader } from "../../../components/Loader";
import FormikErrorScrollToView from "../../../components/FormikErrorScrollToView";
import Logo from "../../../../assets/icons/logo.png";
import * as Yup from "yup";
import * as auth from "../_redux/authRedux";

const initialValues = {
  parentId: "",
  childrenId: "",
};

function SelectAccount(props) {
  const [loading, setLoading] = useState(false);
  const [enterprises, setEnterprises] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const history = useHistory();

  const SelectAccountSchema = Yup.object().shape({
    parentId: Yup.string()
      .trim()
      .required("Enterprise Account is required"),
    childrenId: Yup.string()
      .trim()
      .required("Children Account is required"),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
    fetchEnterpriseAccount();
  }, []);

  const fetchEnterpriseAccount = () => {
    getEnterprisesAccounts()
      .then((response) => {
        setEnterprises(response?.data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchChildrenAccounts = (value) => {
    if (value) {
      getChildrenAccounts(value)
        .then((response) => {
          setAccounts(response?.data?.data);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setAccounts([]);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: SelectAccountSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        saveAccount(values.parentId, values.childrenId)
          .then(() => {
            props.setParentAccount(values.parentId);
            props.setChildrenAccount(values.childrenId);
            props.setSelectedEnterprise(values.parentId);
            props.fetchSelectedEnterpriseAccount(values.childrenId);
            disableLoading();
            history.push("/shipments");
            setSubmitting(false);
          })
          .catch((error) => {
            if (error?.response?.data?.message) {
              setStatus(error?.response?.data?.message);
            } else {
              setStatus(
                intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_LOGIN",
                })
              );
            }
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);
    },
  });

  return (
    <div className="bg-primary min-h-screen pb-20">
      <img className="w-49 m-auto pt-24 pb-12" src={Logo} alt="Logo" width="200px" height="auto" />
      <div className="bg-white sm:max-w-8xl sm:rounded-20 rounded-xl sm:py-16 sm:px-14 m-auto max-w-95% py-6 px-6">
        <div className="" id="kt_login_signin_form">
          {/* begin::Head */}
          <div className="mb-10 mb-lg-18">
            <p className="text-darkBlack sm:text-22px text-lg font-semibold">Select Enterprise/Account</p>
          </div>
          <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
            <FormikProvider value={formik}>
              <FormikErrorScrollToView formik={formik} />
              <div className="form-group fv-plugins-icon-container relative">
                <Field
                  name="parentId"
                  component={() => (
                    <ReactSelect
                      name="parentId"
                      label="Enterprise"
                      required={true}
                      isMulti={false}
                      onChange={(selected) => {
                        fetchChildrenAccounts(selected.value);
                        formik.setFieldValue("parentId", selected.value);
                        formik.setFieldValue("childrenId", "");
                      }}
                      value={formik.values.parentId}
                      options={enterprises.map((item) => {
                        return {
                          value: item._id,
                          label: item.accountName,
                        };
                      })}
                      error={formik.touched.parentId ? formik.errors.parentId : ""}
                    />
                  )}
                />
              </div>
              <div className="form-group fv-plugins-icon-container mt-5 relative">
                <Field
                  name="childrenId"
                  component={() => (
                    <ReactSelect
                      name="childrenId"
                      label="Account"
                      required={true}
                      isMulti={false}
                      onChange={(selected) => formik.setFieldValue("childrenId", selected.value)}
                      value={formik.values.childrenId}
                      options={accounts.map((item) => {
                        return {
                          value: item._id,
                          label: item.accountName,
                        };
                      })}
                      error={formik.touched.childrenId ? formik.errors.childrenId : ""}
                    />
                  )}
                />
              </div>
              <button type="submit" aria-label="Save" disabled={formik.isSubmitting} className="bg-primary text-white rounded-lg w-full p-3 mb-6 mt-8">
                <span>Save</span>
                {loading && <Loader />}
              </button>
            </FormikProvider>
          </form>
          {/*end::Form*/}
        </div>
      </div>
    </div>
  );
}

export default injectIntl(connect((auth) => ({ auth: auth }), auth.actions)(SelectAccount));
