import { createSlice } from "@reduxjs/toolkit";

const initialProductsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  selectedOrder: null,
  lastError: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState: initialProductsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    setOrders: (state, action) => {
      const { items, totalItems } = action.payload.data;
      state.entities = items || null;
      state.totalCount = totalItems || 0;
      state.listLoading = false;
    },
    resetOrders: (state) => {
      state.entities = null;
      state.totalCount = 0;
      state.listLoading = false;
    },
    setSelectedOrder: (state, action) => {
      state.selectedOrder = action.payload?.data;
      state.actionsLoading = false;
    },
    clearSelectedOrder: (state) => {
      state.selectedOrder = null;
    }
  },
});
