import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import SelectAccount from "./modules/Auth/pages/SelectAccount";

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth && auth.user != null,
    }),
    shallowEqual
  );

  ///Routing
  return (
    <Switch>
      {!isAuthorized ? (
        <Route>
          <AuthPage />
        </Route>
      ) : (
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/select-account" exact component={SelectAccount} />

      <Route path="/logout" component={Logout} />

      {!isAuthorized ? <Redirect to="/auth/login" /> : <BasePage />}
    </Switch>
  );
}
