import { notificationSlice, callTypes } from "./notificationSlice";
import { showResponseMessage } from "../../ToastMessage/_redux/toastAction";
import * as auth from "../../Auth/_redux/authRedux"
import * as requestFromServer from "./notificationService";
import { addSocketComment } from "../../../modules/Issues/_redux/issuesActions";
import { io } from "socket.io-client";

const { actions } = notificationSlice;

let socket = ""
/** Socket Connection */
export const get_socket_connection = () => (dispatch) => {
  if (socket) {
    dispatch(disconnect_specific_user(socket.id))
  }

  socket = io(process.env.REACT_APP_NOTIFICATION_SERVICE_API_URL, {
    path: `${process.env.REACT_APP_NOTIFICATION_SERVICE_API_URL_PATH}socket.io/`,
    withCredentials: true,
    extraHeaders: {
      "authorization": "connection socket"
    },
    transports: ["websocket"], // HTTP long-polling is disabled
    autoConnect: true,
    secure: true
  });

  return socket
}
// 

export const disconnect_socket = ({ socketId }) => () => {
  socket.emit("disconnect_specific_user", { id: socketId })
}

export const save_socket_id = (id) => dispatch => {
  dispatch(actions.saveSocketId(id))
}

export const get_socket_messages = () => (dispatch, getState) => {

  socket.on("REMOVE_ALL_USER", (data) => {
    let updatedUser = { ...getState().auth.user };
    updatedUser.ProfilePreferences = data || {}
    dispatch(actions.saveSocketId(null))
    dispatch(auth.actions.setUser(updatedUser));
  })
  socket.on("REJOIN_ALL_USER", (data) => {
    if (data.room === `${getState().auth.parentId}-${getState().auth.childrenId}`) {
      socket.emit("join-room", data.room);
      socket.emit("user-join-room", getState()?.user?._id);
    }
  })
  /** Account Service Sockets */
  socket.on("UPDATE_USER_PROFILE_PREFERENCE", function (data) {
    let updatedUser = { ...getState().auth.user };
    updatedUser.ProfilePreferences = data && data.response_data ? JSON.parse(data.response_data)?.ProfilePreferences || {} : {}
    if (getState().auth.user.ProfilePreferences.complianceRules !== JSON.parse(data.response_data)?.ProfilePreferences.complianceRules) {
      dispatch(actions.toggleReceiveSocket())
      dispatch(auth.actions.setUser(updatedUser));
      dispatch(actions.socketHeaderNotifications(data))
    } else if (getState().auth.user.ProfilePreferences.enabledNotifications !== JSON.parse(data.response_data)?.ProfilePreferences.enabledNotifications) {
      dispatch(auth.actions.setUser(updatedUser));
      dispatch(actions.socketHeaderNotifications(data))
    } else {
      dispatch(actions.socketNotifications(data))
    }
  });

  socket.on("UPDATE_NOTIFICATION_STATUS", data => {
    const updatedDoc = data.data.response && JSON.parse(data.data.response)
    dispatch(actions.updateNotificationStatus({ data: (updatedDoc || []), unReadNotifications: data.data.unReadNotifications }));
  })
  /** Notifications All */
  socket.on("UPDATE_ALL_NOTIFICATIONS_STATUES", data => {
    dispatch(actions.updateNotificationAllStatuses(data))
  })
  /** Shipment Sockets */
  socket.on("Cancel Shipment", data => {
    dispatch(actions.socketNotifications(data))
  })
  socket.on("Bulk Shipment Upload", data => {
    dispatch(actions.socketNotifications(data))
  })
  /** Shipping Service Sockets */
  socket.on("Concise", data => {
    dispatch(actions.socketNotifications(data))
  })
  socket.on("Product Bulk Upload", data => {
    dispatch(actions.socketNotifications(data))
  })
  socket.on("Non Alocholic Product Bulk Upload", data => {
    dispatch(actions.socketNotifications(data))
  })
  socket.on("Bundle Product Bulk Upload", data => {
    dispatch(actions.socketNotifications(data))
  })
  socket.on("Alocholic Product Bulk Upload", data => {
    dispatch(actions.socketNotifications(data))
  })
  /** Order Service */
  socket.on("Order Update", data => {
    dispatch(actions.socketNotifications(data))
  })
  socket.on("Order Archive & Unarchive", data => {
    dispatch(actions.socketNotifications(data))
  })
  /** Ticket Module */
  socket.on("Ticket Comment", data => {
    if (data && data.response_data) {
      const newData = JSON.parse(data.response_data)
      dispatch(addSocketComment(newData))
    }
  })
  socket.on("connect_error", (err) => {
    // revert to classic upgrade
    localStorage.setItem("Socket Error", err.message)
    // console.log("Error------: ", err)
    socket.io.opts.transports = ["polling", "websocket"];
  });
  socket.on("disconnect", () => {
    dispatch(actions.saveSocketId(null))
  })

  socket.on("USER_SESSION_LOGOUT", () => {
    socket.emit("disconnect_specific_user", { id: socket.id })
    localStorage.clear();
    window.location.href = "/auth/login";

  })

}

export const disconnect_specific_user = (id) => () => {
  socket.emit("disconnect_specific_user", { id: id })
}

export const discount_room = (data) => (dispatch) => {
  dispatch(actions.saveSocketId(null))
  socket.emit("DISCOUNT_ROOM_USERS", { room: data.collectionId, data: data.data, id: socket.id })
}

export const connect_room = (data) => (dispatch) => {
  dispatch(get_socket_connection())
  socket.on("connect", function () {
    dispatch(save_socket_id(socket.id))
    socket.emit("RE_JOIN_ROOM", { room: data.collectionId, data: data.data });
  });
}

export const get_notifications = (data) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.clearNotifications());
  return requestFromServer
    .getNotifications(data)
    .then((response) => {
      if (response.data) {
        dispatch(actions.setNotifications(response.data.data.items || []));
      }
    })
    .catch((error) => {
      dispatch(actions.catchError({ callType: callTypes.list }));
      if (data.itemsPerPage !== 5) {
        dispatch(
          showResponseMessage({
            message: "Notification get failed!",
            messageText: error?.response?.data?.message || error.message,
            messageType: "error",
            type: "Error",
          })
        );
      }
    });
}

export const updateNotificationStatuses = (data) => (dispatch) => {
  return requestFromServer
    .notificationStatusUpdate({ ...data, socketId: socket.id })
    .then((response) => {
      if (response.data) {
        dispatch(actions.updateNotificationStatus({ data: (response.data.data.data || []), unReadNotifications: response.data.data.totalUnReadNotifications }));
      }
    })
    .catch((error) => {
      dispatch(actions.catchError({ callType: callTypes.list }));
      dispatch(
        showResponseMessage({
          message: "Notification Status Update Failed!",
          messageText: error?.response?.data?.message || error.message,
          messageType: "error",
          type: "Error",
        })
      );
    });
}

export const get_all_notifications = (data) => (dispatch) => {
  dispatch(actions.startCallAllNoti({ callType: callTypes.list }));
  dispatch(actions.clearAllNotifications());
  return requestFromServer
    .getNotifications(data)
    .then((response) => {
      if (response.data) {
        dispatch(actions.setAllNotifications({ data: response.data.data.items || [], hasNextPage: response.data.data.hasNextPage, totalItems: response.data.data.totalItems, readAllNotifications: response.data.data.totalReadNotifications }));
      }
    })
    .catch((error) => {
      dispatch(actions.catchErrorAllNoti({ callType: callTypes.list }));
      dispatch(
        showResponseMessage({
          message: "Notification Get Failed!",
          messageText: error?.response?.data?.message || error.message,
          messageType: "error",
          type: "Error",
        })
      );
    });
}

export const get_next_notifications = (data) => (dispatch) => {
  dispatch(actions.startCallAllNoti({ callType: callTypes.list }));
  return requestFromServer
    .getNotifications(data)
    .then((response) => {
      if (response.data) {
        dispatch(actions.setAllNotifications({ data: response.data.data.items || [], hasNextPage: response.data.data.hasNextPage, totalItems: response.data.data.totalItems, readAllNotifications: response.data.data.totalReadNotifications }));
      }
    })
    .catch((error) => {
      dispatch(actions.catchErrorAllNoti({ callType: callTypes.list }));
      dispatch(
        showResponseMessage({
          message: "Notification Get Failed!",
          messageText: error?.response?.data?.message || error.message,
          messageType: "error",
          type: "Error",
        })
      );
    });
}