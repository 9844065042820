import * as requestFromServer from "./issuesService";
import { issuesSlice, callTypes } from "./issuesSlice";
import { showResponseMessage } from "../../ToastMessage/_redux/toastAction";

const { actions } = issuesSlice;

export const searchIssuesByParams = (body) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .searchIssuesByParams(body)
    .then((response) => {
      if (response?.data?.data) {
        dispatch(actions.setIssues({ data: response?.data?.data }, { callType: callTypes.list }));
      } else {
        dispatch(actions.setIssues({ data: { item: [], totalItems: 0 } }, { callType: callTypes.list }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't Get Issues";
      dispatch(actions.setIssues({ data: [] }, { callType: callTypes.list }));
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const addIssue = (body, onSuccess) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addIssue(body)
    .then((response) => {
      if (response.data.statusCode === 200) {
        dispatch(
          showResponseMessage({
            message: "Ticket",
            messageText: response?.data?.message || "Ticket Created Successfully!.",
            messageType: "success",
            type: "Success",
          })
        );
        onSuccess();
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't add Ticket";
      dispatch(
        showResponseMessage({
          message: "Can't add Ticket",
          messageText: error?.response?.data?.message || "Can't add Ticket",
          messageType: "error",
          type: "Error",
        })
      );
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const addComment = (body, onSuccess) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .addComment(body)
    .then((response) => {
      if (response?.data?.data && response?.data?.statusCode === 200) {
        dispatch(actions.setSelectedIssue({ data: response?.data?.data[0] }));
        onSuccess();
      } else {
        dispatch(actions.setSelectedIssue({ data: null }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't added comment";
      dispatch(
        showResponseMessage({
          message: "Unable to add comment",
          messageText: error?.response?.data?.message || "Unable to add comment",
          messageType: "error",
          type: "Error",
        })
      );
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const editIssue = (issueId, body, onSuccess) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .editIssue(issueId, body)
    .then((response) => {
      if (response?.data && response.data?.statusCode === 200) {
        dispatch(
          showResponseMessage({
            message: "Issue",
            messageText: response?.data?.message || "Issue Edited Successfully!.",
            messageType: "success",
            type: "Success",
          })
        );
      }
      onSuccess();
    })
    .catch((error) => {
      error.clientMessage = "Can't edit Issue";
      dispatch(
        showResponseMessage({
          message: "Can't edit Issue",
          messageText: error?.response?.data?.message || "Can't edit Issue",
          messageType: "error",
          type: "Error",
        })
      );
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getIssueById = (body, onError) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getIssueById(body)
    .then((response) => {
      if (response?.data?.data && response?.data?.statusCode === 200) {
        dispatch(actions.setSelectedIssue({ data: response?.data?.data[0] }));
      } else {
        dispatch(actions.setSelectedIssue({ data: null }));
        onError();
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't Get Issue By Id";
      dispatch(actions.setSelectedIssue({ data: null }));
      dispatch(
        showResponseMessage({
          message: "Can't Found Issue",
          messageText: error?.response?.data?.message || "Can't Found Issue",
          messageType: "error",
          type: "Error",
        })
      );
      onError();
    });
};

export const addSocketComment = (data) => (dispatch, getState) => {
  const selectedIssue = { ...getState().issues.selectedIssue };
  if (selectedIssue._id === data[0]?._id && selectedIssue.threadId === data[0]?.threadId) {
    dispatch(actions.setSelectedIssue({ data: data[0] }));
  }
}