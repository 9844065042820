import { createSlice } from "@reduxjs/toolkit";

const initialNotificationState = {
  listLoading: false,
  actionsLoading: false,
  allNotificationLoading: false,
  allNotifcationActionsLoading: false,
  totalCount: 0,
  notifications: [],
  allNotifications: [],
  error: "",
  receive_socket: false,
  hasNextPage: false,
  socketId: "",
  unReadNotifications: true
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const notificationSlice = createSlice({
  name: "notifications",
  initialState: initialNotificationState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    saveSocketId: (state, action) => {
      state.socketId = action.payload
    },
    socketNotifications: (state, action) => {
      state.notifications = (state.notifications || []).length > 4 ? [action.payload, ...(state.notifications || []).slice(0, -1)] : [action.payload, ...(state.notifications || [])];
      state.allNotifications = [action.payload, ...state.allNotifications || []];
      state.unReadNotifications = true;
    },
    socketHeaderNotifications: (state, action) => {
      state.notifications = (state.notifications || []).length > 4 ? [action.payload, ...(state.notifications || []).slice(0, -1)] : [action.payload, ...(state.notifications || [])];
      state.unReadNotifications = true;
    },

    clearNotifications: (state) => {
      state.notifications = [];
    },
    clearAllNotifications: (state) => {
      state.totalCount = 0;
      state.allNotifications = [];
    },
    startCallAllNoti: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.allNotificationLoading = true;
      } else {
        state.allNotifcationActionsLoading = true;
      }
    },
    catchErrorAllNoti: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.allNotificationLoading = false;
      } else {
        state.allNotifcationActionsLoading = false;
      }
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
      state.listLoading = false;
    },
    updateNotificationStatus: (state, action) => {
      state.notifications = state.notifications.map(item => {
        if (action.payload.data.filter(ite => ite._id === item._id).length > 0) {
          return { ...item, message_read: true }
        }
        return item
      })
      state.allNotifications = state.allNotifications.map(item => {
        if (action.payload.data.filter(ite => ite._id === item._id).length > 0) {
          return { ...item, message_read: true }
        }
        return item
      })
      state.unReadNotifications = +action.payload.unReadNotifications > 0 ? true : false
    },

    setAllNotifications: (state, action) => {
      state.allNotifications = [...state.allNotifications || [], ...action.payload.data || []];
      state.hasNextPage = action.payload.hasNextPage;
      state.totalCount = action.payload.totalItems
      state.allNotificationLoading = false;
      state.unReadNotifications = action.payload.readAllNotifications
    },
    setNextNotifications: (state, action) => {
      state.allNotifications = [...state.allNotifications, ...action.payload.data] || [];
      state.allNotificationLoading = false;
    },
    toggleReceiveSocket: (state) => {
      state.receive_socket = !state.receive_socket
    },
    updateNotificationAllStatuses: (state, action) => {
      state.allNotifications = state.allNotifications.map(item => {
        return {
          ...item, message_read: true
        }
      });
      state.notifications = state.notifications.map(item => {
        return {
          ...item, message_read: true
        }
      });
      state.unReadNotifications = action.payload;
    }
  },
});
