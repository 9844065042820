import React, { useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { BellIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { UserCircleIcon } from "@heroicons/react/outline";
import LogoBlue from "../../../assets/icons/logoblue.svg";
import CaredDown from "../../../assets/icons/carret-down.svg";
import HeaderNotification from "../Notification/Header/HeaderNotification";

export const Header = (props) => {
  const { toggleSideMenu, isNotificationsMenuOpen, toggleNotificationsMenu, isMenuListOpen, toggleMenuList, setIsOpen, menuListRef } = props;
  const { selectedEnterprise, selectedChildAccount, user } = useSelector((state) => state.auth, shallowEqual);
  const { notifications } = useSelector(({ notifications }) => {
    return {
      notifications: notifications.notifications,
    };
  });

  useEffect(() => { }, [user?.updatedAt]);

  return (
    <>
      {process.env.REACT_APP_TESTING === "true" && (
        <div className="flex justify-center bg-red-600 h-8 shadow-lg md:px-6 px-3">
          <span className="text-sm text-white font-medium py-2">This is Test Environment </span>
        </div>
      )}
      <header className="z-10 py-4 bg-white dark:bg-gray-800 shadow-xs">
        <div className="container flex items-center justify-between h-full md:px-6 px-3 mx-auto  ">
          <button className="p-1 mr-5 -ml-1 rounded-md lg:hidden focus:outline-none focus:shadow-outline-purple" onClick={toggleSideMenu} aria-label="Menu">
            <svg className="w-6 h-6" id="Group_550" data-name="Group 550" xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
              <path id="Path_757" data-name="Path 757" d="M16,1a1,1,0,0,0-1-1H1A1,1,0,1,0,1,2H15a1,1,0,0,0,1-1Z" transform="translate(0 0)" fill="#1e1014" />
              <path id="Path_758" data-name="Path 758" d="M16,13a1,1,0,0,0-1-1H1a1,1,0,0,0,0,2H15a1,1,0,0,0,1-1Z" transform="translate(0 0)" fill="#1e1014" />
              <path id="Path_759" data-name="Path 759" d="M15,6a1,1,0,1,1,0,2H7A1,1,0,0,1,7,6Z" transform="translate(-6 0)" fill="#1e1014" />
            </svg>
          </button>
          <div className="flex justify-between xl:w-2/4 w-full items-center">
            <img src={LogoBlue} className="w-32 lg:hidden" alt="BoxCheck" width="128px" height="auto" />
          </div>

          <div className="flex flex-1 lg:mr-32">
            <img src={LogoBlue} className="w-32 md:hidden" alt="BoxCheck" width="128px" height="auto" />
          </div>
          <ul className="flex items-center flex-shrink-0 md:space-x-5 justify-end">
            <li className="relative">
              <button
                className="relative align-middle rounded-md focus:outline-none focus:shadow-outline-purple"
                aria-label="Notifications"
                aria-haspopup="true"
                onClick={toggleNotificationsMenu}
                title="Notifications"
              >
                {(notifications || [])?.filter((item) => item.message_read === false)?.length > 0 ? (
                  <>
                    <BellIcon className="h-7 w-7 text-gray-500" />
                    <span
                      aria-hidden="true"
                      className="absolute top-1 right-1 inline-block w-3 h-3 transform translate-x-1 -translate-y-1 bg-red-600 border-2 border-white rounded-full dark:border-gray-800"
                    ></span>
                  </>
                ) : (
                  <BellIcon className="h-7 w-7 text-gray-500" />
                )}
              </button>
              {isNotificationsMenuOpen && <HeaderNotification onClick={toggleNotificationsMenu} />}
            </li>
            {user?.imageUrl ? (
              <li className="relative ml-2">
                <button className="align-middle rounded-full focus:shadow-outline-purple focus:outline-none" aria-label="Account" aria-haspopup="true">
                  <img
                    id="imgButton"
                    onClick={toggleMenuList}
                    className="object-cover w-8 h-8 rounded-50%"
                    src={user?.imageUrl}
                    alt="Menu"
                    aria-hidden="true"
                    width="auto"
                    height="auto"
                  />
                </button>
              </li>
            ) : (
              <UserCircleIcon className="w-8 h-8 rounded-50%" />
            )}
            <li ref={menuListRef} className="relative flex cursor-pointer">
              <div className="md:flex hidden">
                <p className="text-darkBlack text-sm mr-2" id="button">
                  {user?.firstName} {user?.lastName}
                </p>
                <img className="w-[10px]" src={CaredDown} alt="CaretDown" width="10px" height="auto" />
              </div>

              {isMenuListOpen && (
                <template className="block">
                  <ul
                    className="absolute md:-right-2 -right-1  w-53  py-4 md:top-10 top-6 space-y-2 text-gray-600 bg-white border border-gray-100 rounded-md shadow-3xl dark:text-gray-300 dark:border-gray-700 dark:bg-gray-700 z-20"
                  // ref={headerRef}
                  >
                    <li>
                      <div className="px-4">
                        <p className="text-xs text-lighterGray">Enterprise Name</p>
                        <h3 className="text-darkBlack text-sm font-medium">{selectedEnterprise?.accountName}</h3>
                      </div>
                      <div className="px-4">
                        <p className="text-xs text-lighterGray  mt-2">Account Name</p>
                        <h3 className="text-darkBlack text-sm font-medium">{selectedChildAccount?.accountName}</h3>
                      </div>
                      <hr className="my-3" />
                      <div
                        className="text-primary text-sm cursor-pointer px-4"
                        onClick={() => {
                          toggleMenuList();
                          setIsOpen(true);
                        }}
                      >
                        Change Account
                      </div>
                      <Link
                        onClick={toggleMenuList}
                        className="inline-flex items-center justify-between w-full
											 text-sm text-primary transition-colors
											duration-150 px-4 mt-2"
                        to="/settings/my-profile"
                      >
                        <span>My Profile</span>
                      </Link>
                      <Link
                        className="inline-flex items-center justify-between w-full
											 text-sm text-primary transition-colors
											duration-150 px-4 mt-2"
                        to="/logout"
                      >
                        <span>Log Out</span>
                      </Link>
                    </li>
                  </ul>
                </template>
              )}
            </li>
          </ul>
        </div>
      </header>
    </>
  );
};
