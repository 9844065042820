import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import { shipmentsSlice } from "../app/modules/Shipments/_redux/shipmentSlice";
import { productsSlice } from "../app/modules/Products/_redux/productsSlice";
import { toastSlice } from "../app/modules/ToastMessage/_redux/toastSlice";
import { dashboardSlice } from "../app/modules/Dashboard/_redux/dashboardSlice";
import { licencesSlice } from "../app/modules/License/_redux/licenceSlice";
import { reportsSlice } from "../app/modules/Reports/_redux/reportsSlice";
import { ordersSlice } from "../app/modules/Orders/_redux/ordersSlice";
import { notificationSlice } from "../app/modules/Notification/_redux/notificationSlice";
import { integrationSlice } from "../app/modules/Profile/_redux/integrationSlice";
import { issuesSlice } from "../app/modules/Issues/_redux/issuesSlice";

const rootPersistConfig = {
  key: "root",
  storage: storage,
  whitelist: [""],
};

const shipmentsPersistConfig = {
  key: "shipments",
  storage: storage,
  whitelist: ["shipmentNumber", "shipment_search_params", "shipmentViewType"],
};

const reducers = combineReducers({
  auth: auth.reducer,
  shipments: persistReducer(shipmentsPersistConfig, shipmentsSlice.reducer),
  products: productsSlice.reducer,
  toastMessages: toastSlice.reducer,
  dashboard: dashboardSlice.reducer,
  licences: licencesSlice.reducer,
  reports: reportsSlice.reducer,
  orders: ordersSlice.reducer,
  notifications: notificationSlice.reducer,
  integration: integrationSlice.reducer,
  issues: issuesSlice.reducer,
});

export const rootReducer = persistReducer(rootPersistConfig, reducers);

export function* rootSaga() {
  yield all([auth.saga()]);
}
