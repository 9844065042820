import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken, getSelectedAccount } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  UpdateUser: "[Update User] Action",
  SetParentAccount: "[Set Parent Account] Action",
  SetChildrenAccount: "[Set Children Account] Action",
  setParentAccountID: "[set Parent Account Id] Action",
  setChildrenAccountID: "[Set Children Account] Action",
  SetSelectedChildAccount: "[select Children Account] Action",
  SetSelectedEnterpriseAccount: "[select Enterprise Account] Action",
  FetchSelectedChildAccount: "[fetch Children Account] Action",
  FetchSelectedEnterpriseAccount: "[fetch Enterprise Account] Action",
};

const initialAuthState = {
  user: undefined,
  parentId: undefined,
  childrenId: undefined,
  authToken: undefined,
  selectedEnterprise: undefined,
  selectedChildAccount: undefined,
};

export const reducer = persistReducer(
  {
    storage,
    key: "v726-demo1-auth",
    whitelist: ["authToken", "parentId", "childrenId"],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;
        return { authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.setChildrenAccountID: {
        const { childrenId } = action.payload;
        return { ...state, childrenId };
      }
      case actionTypes.setParentAccountID: {
        const { parentId } = action.payload;
        return { ...state, parentId };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.UpdateUser: {
        const { user } = action.UpdateUser;
        return { ...state, user };
      }
      case actionTypes.SetSelectedChildAccount: {
        const { selectedChildAccount } = action.payload;
        return { ...state, selectedChildAccount, selectedEnterprise: selectedChildAccount?.parentAccount };
      }
      case actionTypes.SetSelectedEnterpriseAccount: {
        const { selectedEnterprise } = action.payload;
        return { ...state, selectedEnterprise };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken) => ({
    type: actionTypes.Login,
    payload: { authToken },
  }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({
    type: actionTypes.UserLoaded,
    payload: { user },
  }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  setParentAccount: (parentId) => ({
    type: actionTypes.setParentAccountID,
    payload: { parentId },
  }),
  setChildrenAccount: (childrenId) => ({
    type: actionTypes.setChildrenAccountID,
    payload: { childrenId },
  }),
  addSelectedChildAccount: (selectedChildAccount) => ({
    type: actionTypes.SetSelectedChildAccount,
    payload: { selectedChildAccount },
  }),
  fetchSelectedChildAccount: (childId) => ({
    type: actionTypes.FetchSelectedChildAccount,
    payload: { childId },
  }),
  setSelectedEnterprise: (selectedEnterprise) => ({
    type: actionTypes.SetSelectedEnterpriseAccount,
    payload: { selectedEnterprise },
  }),
  fetchSelectedEnterpriseAccount: (parentId) => ({
    type: actionTypes.FetchSelectedEnterpriseAccount,
    payload: { parentId },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const {
      data: { data: user },
    } = yield getUserByToken();

    if (user.defaultAccounts) {
      yield put(actions.setChildrenAccount(user.defaultAccounts.child));
      yield put(actions.setParentAccount(user.defaultAccounts.parent));
      // yield put(actions.fetchSelectedChildAccount(user.defaultAccounts.child));
      // yield put(
      // 	actions.fetchSelectedEnterpriseAccount(user.defaultAccounts.parent)
      // );
    }
    yield put(actions.fulfillUser(user));
  });
  yield takeLatest(
    actionTypes.FetchSelectedChildAccount,
    function* FetchSelectedChildAccount(action) {
      const { childId } = action.payload;
      const {
        data: { data },
      } = yield getSelectedAccount(childId);
      yield put(actions.addSelectedChildAccount(data));
    }
  );
  yield takeLatest(
    actionTypes.FetchSelectedEnterpriseAccount,
    function* FetchSelectedEnterpriseAccount(action) {
      const { parentId } = action.payload;
      const {
        data: { data },
      } = yield getSelectedAccount(parentId);
      yield put(actions.setSelectedEnterprise(data));
    }
  );
}
