import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import Profile from "../../../../assets/images/profile.jpg";

const HeaderNotificationList = ({ item, updateNotificationStatus = null }) => {
  const { parentId, childrenId, user } = useSelector(({ auth }) => {
    return {
      parentId: auth.parentId,
      childrenId: auth.childrenId,
      user: auth.user,
    };
  });
  return (
    <li
      className="px-4 flex justify-between gap-2 items-center py-4 border-b  hover:bg-[#F6F7F8]"
      key={item._id}
      onClick={() => !item.message_read && updateNotificationStatus({ parentAccount: parentId, childAccount: childrenId, ids: item._id })}
    >
      <div className="flex gap-3">
        <div className="">
          <div className="bg-primary rounded-50% h-[32px] w-[32px] flex justify-center overflow-hidden">
            {/* <LockClosedcon className="text-white w-5" /> */}
            <img src={user?.imageUrl || Profile} className="text-white object-cover w-full" alt="Profile" width="100%" height="auto" />
          </div>
        </div>
        <div>
          <p className="text-darkBlack font-normal text-sm">
            <span className="font-medium">{item?.sender_name || ""} </span> {item?.message || ""}
          </p>
          <p className="text-lighterGray text-xs">{item?.createdAt && moment(item?.createdAt).calendar()}</p>
        </div>
      </div>
      <div>{!item.message_read && <div className="h-[7px] w-[7px] border-primary border rounded-50% bg-[#90CDF4]"></div>}</div>
    </li>
  );
};
export default HeaderNotificationList;
