import axios from "axios";

export function searchIssuesByParams(body) {
  return axios.post(`${process.env.REACT_APP_ISSUE_SERVICE_API_URL_V2}/issue/list`, body);
}

export function addIssue(body) {
  return axios.post(`${process.env.REACT_APP_ISSUE_SERVICE_API_URL_V2}/issue/create`, body);
}

export function addComment(body) {
  return axios.post(`${process.env.REACT_APP_ISSUE_SERVICE_API_URL_V2}/issue/addComment`, body);
}

export function editIssue(issueId, body) {
  return axios.post(`${process.env.REACT_APP_ISSUE_SERVICE_API_URL_V2}/issue/${issueId}`, body);
}

export function getIssueById(body) {
  return axios.post(`${process.env.REACT_APP_ISSUE_SERVICE_API_URL_V2}/issue/getById`, body);
}

export function archiveIssueById(body) {
  return axios.post(`${process.env.REACT_APP_ISSUE_SERVICE_API_URL_V2}/issue/archive`, body);
}

export function getAssignees() {
  return axios.get(`${process.env.REACT_APP_ISSUE_SERVICE_API_URL_V2}/issue/getSupportUsersList`);
}

export function assignIssue(body) {
  return axios.post(`${process.env.REACT_APP_ISSUE_SERVICE_API_URL_V2}/issue/assignIssue`, body);
}

export function updateStatus(body) {
  return axios.post(`${process.env.REACT_APP_ISSUE_SERVICE_API_URL_V2}/issue/updateStatus`, body);
}
