import React, { useEffect, useRef, useState, lazy, Suspense, createContext } from "react";
import { Header } from "../components/Header/Header";
import {
  get_notifications, get_socket_connection, save_socket_id, get_socket_messages
} from "../modules/Notification/_redux/notificationActions";
import { connect, useSelector, shallowEqual, useDispatch } from "react-redux";
import { LayoutSplashScreen } from "../Context/LayoutContext";
import ToastMessage from "../modules/ToastMessage";
import * as auth from "../modules/Auth/_redux/authRedux";
import useIsFirstMount from "../hooks/useIsFirstMount";
export const ScrollToTop = createContext({ scrollToTop: () => { } });

const ChangeAccount = lazy(() => import("../modules/Auth/pages/ChnageAccount"));
const ChangeDefaultCarrier = lazy(() => import("../modules/Auth/pages/ChangeDefaultCarrier"));
const Drawer = lazy(() => import("../components/Drawer/Drawer"));
const Sidebar = lazy(() => import("../components/Sidebar/Sidebar"));

const Layout = (props) => {
  const dispatch = useDispatch();
  const firstMount = useIsFirstMount()
  const [isNotificationsMenuOpen, NotificationsMenuOpen] = useState(false);
  const [isMenuListOpen, MenuListOpen] = useState(false);
  const [isSidebarOpen, SidebarOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isChangeDEfaultCarrierModalOpen, setChangeDefaultCarrierModalOpen] = useState(false);

  const { parentId, childrenId, user } = useSelector((state) => state.auth, shallowEqual);
  const { ProfilePreferences } = user
  const toggleNotificationsMenu = () => {
    NotificationsMenuOpen(!isNotificationsMenuOpen);
    MenuListOpen(false);
  };
  const toggleMenuList = () => {
    MenuListOpen(!isMenuListOpen);
    NotificationsMenuOpen(false);
  };
  const toggleSideMenu = () => {
    SidebarOpen(!isSidebarOpen);
  };
  useEffect(() => {
    if (firstMount) {
      if (childrenId && parentId) {
        const data = { parentAccount: parentId, childAccount: childrenId, page: 1, itemsPerPage: 5 };
        if (ProfilePreferences && ProfilePreferences?.enabledNotifications) {
          dispatch(get_notifications(data))
        }
      }
    }
  }, [])

  useEffect(() => {
    if (childrenId) {
      props.fetchSelectedChildAccount(childrenId);
      const data = { parentAccount: parentId, childAccount: childrenId, page: 1, itemsPerPage: 5 };
      dispatch(get_notifications(data))
    }
  }, [parentId, childrenId]);

  const ref = useRef();
  const appRef = useRef(null);
  const menuListRef = useRef(null);
  const drawerRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuListRef.current && event.target.id !== "imgButton" && !menuListRef.current.contains(event.target)) {
        MenuListOpen(false);
      } else if (menuListRef.current && menuListRef.current.contains(event.target)) {
        if (!isMenuListOpen || event.target?.id === "button") {
          toggleMenuList();
        } else {
          MenuListOpen(true);
        }
      }
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        SidebarOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuListRef, isMenuListOpen]);

  useEffect(() => {
    if (childrenId && parentId) {
      /** Socket Connection According To Parent And Child */
      const roomId = `${parentId}-${childrenId}`
      const socket = dispatch(get_socket_connection(roomId))
      socket.on("connect", function () {
        dispatch(save_socket_id(socket.id))
        socket.emit("join-room", roomId);
        socket.emit("user-join-room", user?._id);
      });
      dispatch(get_socket_messages())
    }
  }, [parentId, childrenId]);
  const scrollToTop = () => {
    appRef.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <ScrollToTop.Provider value={{ scrollToTop }} >
      <Suspense fallback={<LayoutSplashScreen />}>
        {
          isOpen && <ChangeAccount isOpen={isOpen} setIsOpen={setIsOpen} />
        }
        {
          isChangeDEfaultCarrierModalOpen && <ChangeDefaultCarrier isOpen={isChangeDEfaultCarrierModalOpen} setIsOpen={setChangeDefaultCarrierModalOpen} />
        }
        <div className="flex h-screen bg-foggySummer" onClick={() => { if (isNotificationsMenuOpen) NotificationsMenuOpen(false) }} >
          {isSidebarOpen ? <Drawer DrawerRef={drawerRef} toggleSideMenu={toggleSideMenu} /> : <Sidebar />}
          <div className="flex flex-col flex-1 w-full">
            <Header
              headerRef={ref}
              menuListRef={menuListRef}
              setIsOpen={setIsOpen}
              isMenuListOpen={isMenuListOpen}
              toggleMenuList={toggleMenuList}
              toggleSideMenu={toggleSideMenu}
              toggleNotificationsMenu={toggleNotificationsMenu}
              isNotificationsMenuOpen={isNotificationsMenuOpen}
              setChangeDefaultCarrierModalOpen={setChangeDefaultCarrierModalOpen}
            />
            <main className="h-full overflow-y-auto relative bg-white"><div ref={appRef}>{props.children}</div></main>
            <ToastMessage />
          </div>
        </div>
      </Suspense>
    </ScrollToTop.Provider >
  );
};

export default connect(null, auth.actions)(Layout);
