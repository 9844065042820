import React from "react";
import denied from "../../../../src/assets/icons/denied.svg";
import completed from "../../../../src/assets/icons/completed.svg";
import close from "../../../../src/assets/icons/close.svg";
export default function MessageToast(props) {
  if (props.autoClose) {
    setTimeout(() => {
      props.closeToaster();
    }, [props.timeOut]);
  }
  let newClassName = props.archive === true ? "border-primary" : props.messageType === "success" ? "border-[#5CB76B]" : props.messageType === "error" ? "border-[#CB2027]" : "";

  if (Array.isArray(props.messageText)) {
    let mt = 6;
    return props.messageText.map((element) => {
      const keys = Object.keys(element);
      const style = {
        top: mt + "rem",
      };
      mt += 7;
      return (
        <div className="fixed right-2 top-right z-40" key={keys[0]} style={style}>
          <div className={`flex gap-4 max-w-[424px] px-5 py-5 shadow bg-white rounded-xl border ${newClassName}`}>
            <div className="w-8">
              <img src={props.messageType === "success" ? completed : denied} className="w-full" alt={props.message} width="100%" height="auto" />
            </div>
            <div className="w-full">
              <p className="text-lg text-darkBlack font-medium">{props.message}</p>
              <p className="text-OlderGray text-base font-normal">{element[keys[0]]}</p>
              {props.archive === true && (
                <a className="btn-secondary w-full mt-4" onClick={props.buttonClick}>
                  Unarchive
                </a>
              )}
            </div>
            <div>
              <button onClick={props.closeToaster} aria-label="Close">
                <img src={close} className="w-4" alt="Close" width="16px" height="auto" />
              </button>
            </div>
          </div>
        </div>
      );
    });
  } else {
    return (
      <div className="fixed right-2 top-24 top-right z-40">
        <div className={`flex gap-4 max-w-[424px] px-5 py-5 shadow bg-white rounded-xl border ${newClassName}`}>
          <div className="w-8">
            <img src={props.messageType === "success" ? completed : denied} className="w-full" alt={props.message} width="100%" height="auto" />
          </div>
          <div className="w-full">
            <p className="text-lg text-darkBlack font-medium">{props.message}</p>
            <p className="text-OlderGray text-base font-normal">{props.messageText}</p>
            {props.archive === true && (
              <a className="btn-secondary w-full mt-4" onClick={props.buttonClick}>
                Unarchive
              </a>
            )}
          </div>
          <div>
            <button onClick={props.closeToaster} aria-label="Close">
              <img src={close} className="w-4" alt="Close" width="16px" height="auto" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}
