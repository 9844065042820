import { createSlice } from "@reduxjs/toolkit";

const initialShipmentState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  locationForEdit: undefined,
  lastError: null,
  shipmentNumber: undefined,
  selectedShipment: null,
  copiedShipment: null,
  updatedProduct: null,
  updatedPackage: null,
  carrierRates: null,
  carrierCountSummary: null,
  orderStatusCountSummary: null,
  shipmentStatusSummary: null,
  labels: undefined,
  viewShipment: undefined,
  showUploadFields: false,
  orderForCreateShipment: null,
  selectedRate: null,
  rates: [],
  shipment_search_params: {
    carriers: "",
    shipmentStatus: "",
    shipmentNumber: "",
    shipTo: {
      firstName: "",
      lastName: "",
      cityName: "",
      stateName: "",
    },
    startDate: "",
    endDate: "",
  },
  reloadApi: false,
  shipmentViewType: true,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const shipmentsSlice = createSlice({
  name: "shipments",
  initialState: initialShipmentState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    updateSelectedProduct: (state, action) => {
      const { selectedProduct } = action.payload;
      state.error = null;
      state.updatedProduct = selectedProduct;
      state.actionsLoading = false;
    },
    updateSelectedPackage: (state, action) => {
      const { selectedPackage } = action.payload;
      state.error = null;
      state.updatedPackage = selectedPackage;
      state.actionsLoading = false;
    },

    // Update Shipment
    updateSelectedShipment: (state, action) => {
      const { shipment } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.selectedShipment = shipment;
      state.rates = shipment?.rates;
      state.shipmentNumber = shipment?.shipmentNumber;
    },
    // Update label
    updateLabels: (state, action) => {
      const labels = action?.payload?.labels;
      state.actionsLoading = false;
      state.error = null;
      state.labels = labels;
    },
    // fetch Selected Shipment
    fetchSelectedShipment: (state, action) => {
      const { shipment } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.updatedProduct = null;
      state.updatedPackage = null;
      state.selectedShipment = shipment;
      state.rates = shipment?.rates;
      state.shipmentNumber = shipment?.shipmentNumber;
    },
    // fetch Selected Shipment
    fetchSelectedShipmentRates: (state, action) => {
      const { rates } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.rates = rates;
    },
    // fetch Selected Shipment
    fetchViewShipment: (state, action) => {
      const { shipment } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.updatedProduct = null;
      state.updatedPackage = null;
      state.viewShipment = shipment;
    },
    saveSelectedShipment: (state) => {
      state.orderForCreateShipment = null;
      state.error = null;
      state.selectedShipment = null;
      state.shipmentNumber = undefined;
    },
    setCopyShipmentObj: (state, action) => {
      state.copiedShipment = action.payload?.copiedShipment;
    },
    setSearchedShipments: (state, action) => {
      state.totalCount = action.payload?.shipment?.totalItems;
      state.entities = action.payload?.shipment?.items;
      state.listLoading = false;
    },
    setSearchParams: (state, actions) => {
      state.shipment_search_params = actions?.payload?.data;
    },
    removeSearchParams: (state) => {
      (state.shipment_search_params = {
        carriers: "",
        shipmentStatus: "",
        shipmentNumber: "",
        shipTo: {
          firstName: "",
          lastName: "",
          cityName: "",
          stateName: "",
        },
        startDate: "",
        endDate: "",
      }),
        (state.reloadApi = !state.reloadApi);
    },
    toggleShipmentViewType: (state, action) => {
      state.shipmentViewType = action.payload.value;
    },
    setShipmentsCount: (state, action) => {
      state.carrierCountSummary = action.payload?.shipmentCount?.carrierCountSummary;
      state.orderStatusCountSummary = action.payload?.shipmentCount?.orderStatusCountSummary;
      state.shipmentStatusSummary = action.payload?.shipmentCount?.shipmentStatusSummary;
      // state.listLoading = false;
    },
    // update rates account on user select
    updateShipmentOnChangeCareerAccount: (state, action) => {
      state.selectedShipment = action.payload?.shipment;
    },
    toggleUploadProductFields: (state, action) => {
      state.showUploadFields = action.payload?.status;
    },
    clearSelectedShipment: (state) => {
      state.selectedShipment = null;
      state.viewShipment = undefined;
    },
    setSelectedRate: (state, action) => {
      state.activeDateObj = action.payload.activeDateObj;
      state.selectedRate = action.payload.selectedRate;
    },
    cleanShipmentState: (state) => {
      state.listLoading = false;
      state.actionsLoading = false;
      state.totalCount = 0;
      state.entities = null;
      state.locationForEdit = undefined;
      state.lastError = null;
      state.shipmentNumber = undefined;
      state.selectedShipment = null;
      state.updatedProduct = null;
      state.updatedPackage = null;
      state.carrierRates = null;
      state.carrierCountSummary = null;
      state.orderStatusCountSummary = null;
      state.shipmentStatusSummary = null;
      state.orderForCreateShipment = null;
      state.labels = undefined;
      state.viewShipment = undefined;
      state.showUploadFields = false;
    },
    setOrderToCreateShipment: (state, action) => {
      state.orderForCreateShipment = action.payload?.order;
    },
    updateRefetchShipmentStatus: (state, action) => {
      state.entities = state.entities?.map((item) => {
        if (item?.trackingCode === action?.payload?.data?.trackingNumber) {
          return {
            ...item,
            shipmentStatus: action?.payload?.data?.tag?.replace(" ", ""),
          };
        }
        return item;
      });
    },
  },
});
