import axios from "axios";

export function getProductsUnitSize() {
  return axios.get(
    `${process.env.REACT_APP_ACCOUNT_SERVICE_API_URL_V1}/settings/volume-unit-size/list?getAll=true&withArchive=false`
  );
}

export function getProductsTypes() {
  return axios.get(
    `${process.env.REACT_APP_ACCOUNT_SERVICE_API_URL_V1}/settings/product-type/list?getAll=true&withArchive=false`
  );
}

export function getContainersTypes() {
  return axios.get(
    `${process.env.REACT_APP_ACCOUNT_SERVICE_API_URL_V1}/containerType?withArchive=true$getAll=true`
  );
}

export function searchTaxCode(userInput) {
  return axios.get(
    `${process.env.REACT_APP_ACCOUNT_SERVICE_API_URL_V1}/settings/taxCodesByProductType?productType=${userInput}`
  );
}

export function getVintages() {
  return axios.get(
    `${process.env.REACT_APP_ACCOUNT_SERVICE_API_URL_V1}/settings/vintage/list?getAll=true&withArchive=`
  );
}

export function getVloumneUnitNumber() {
  return axios.get(
    `${process.env.REACT_APP_ACCOUNT_SERVICE_API_URL_V1}/settings/volume-unit-number/list?getAll=true&withArchive=false`
  );
}

export function getVarietals() {
  return axios.get(
    `${process.env.REACT_APP_ACCOUNT_SERVICE_API_URL_V1}/settings/varietal/list?getAll=true&withArchive=false`
  );
}

export function getBrands(query) {
  return axios.get(
    `${process.env.REACT_APP_PRODUCT_SERVICE_API_URL_V1}/brands/list/?${query}`
  );
}

export function searchAppellation(userInput) {
  return axios.get(
    `${process.env.REACT_APP_ACCOUNT_SERVICE_API_URL_V1}/settings/appellation/nameSuggestions?appellation=${userInput}`
  );
}

export function searchProductSKU(query) {
  return axios.get(
    `${process.env.REACT_APP_PRODUCT_SERVICE_API_URL_V1}/products/filter/productSkuSuggestions/?${query}`
  );
}

export function createAlcoholicProduct(product) {
  return axios.post(
    `${process.env.REACT_APP_PRODUCT_SERVICE_API_URL_V1}/products/alcoholic`,
    product
  );
}

export function updateAlcoholicProduct(product, id) {
  return axios.put(
    `${process.env.REACT_APP_PRODUCT_SERVICE_API_URL_V1}/products/alcoholic-product/${id}`,
    product
  );
}

export function createBundleProducts(product) {
  return axios.post(
    `${process.env.REACT_APP_PRODUCT_SERVICE_API_URL_V1}/products/bundle-product`,
    product
  );
}
export function updateBundleProducts(product, id) {
  return axios.put(
    `${process.env.REACT_APP_PRODUCT_SERVICE_API_URL_V1}/products/bundle-product/${id}`,
    product
  );
}

export function createNonAlcoholicProduct(product) {
  return axios.post(
    `${process.env.REACT_APP_PRODUCT_SERVICE_API_URL_V1}/products/non-alcoholic`,
    product
  );
}

export function updateNonAlcoholicProduct(product, id) {
  return axios.put(
    `${process.env.REACT_APP_PRODUCT_SERVICE_API_URL_V1}/products/non-alcoholic-product/${id}`,
    product
  );
}

export function getProducts(query) {
  return axios.get(
    `${process.env.REACT_APP_PRODUCT_SERVICE_API_URL_V1}/products/list?${query}`
  );
}

export function searchProductByParams(searchParams) {
  return axios.get(
    `${process.env.REACT_APP_PRODUCT_SERVICE_API_URL_V2}/products/listByProductFilters?${searchParams}`
    // `${process.env.REACT_APP_PRODUCT_SERVICE_API_URL_V1}/products/filter/products?${searchParams}`
  );
}
export function searchProductByParamsCSVDownload(searchParams) {
  return axios.get(
    `${process.env.REACT_APP_PRODUCT_SERVICE_API_URL_V2}/products/listByProductFilters?${searchParams}`
  );
}
export function getProductById(productId) {
  return axios.get(
    `${process.env.REACT_APP_PRODUCT_SERVICE_API_URL_V1}/products/${productId}`
  );
}

export function archiveProduct(productId) {
  return axios.put(
    `${process.env.REACT_APP_PRODUCT_SERVICE_API_URL_V1}/products/archive/${productId}`,
    { status: "archive" }
  );
}

export function activeProduct(productId) {
  return axios.put(
    `${process.env.REACT_APP_PRODUCT_SERVICE_API_URL_V1}/products/un-archive/${productId}`,
    { status: "active" }
  );
}
/** Bulk Upload Products */
export function nonWinBulkProduct(product) {
  return axios.post(
    `${process.env.REACT_APP_PRODUCT_SERVICE_API_URL_V2}/products/non-alcoholic-products/bulkUpload`,
    product
  );
}

export function kitGiftBulkProduct(product) {
  return axios.post(
    `${process.env.REACT_APP_PRODUCT_SERVICE_API_URL_V2}/products/bundle-products/bulkUpload`,
    product
  );
}
export function wineBulkProduct(product) {
  return axios.post(
    `${process.env.REACT_APP_PRODUCT_SERVICE_API_URL_V2}/products/alcoholic-products/bulkUpload`,
    product
  );
}
