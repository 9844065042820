import { createSlice } from "@reduxjs/toolkit";

const initialReportsState = {
  listLoading: false,
  actionsLoading: false,
  error: null,
  complianceData: null,
  ageVerificationData: null,
  shipmentData: null,
  totalCount: 0,
  carrierCountSummary: null,
  orderStatusCountSummary: null,
  shipmentStatusSummary: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState: initialReportsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    clearEntities: (state) => {
      state.error = null;
      state.listLoading = false;
      state.actionsLoading = false;
      state.complianceData = null;
      state.ageVerificationData = null;
      state.shipmentData = null;
      state.totalCount = 0;
      state.carrierCountSummary = null;
      state.orderStatusCountSummary = null;
      state.shipmentStatusSummary = null;
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    fetchComplianceData: (state, action) => {
      const { data, totalCount } = action.payload;
      state.error = null;
      state.complianceData = data;
      state.totalCount = totalCount;
      state.listLoading = false;
    },
    fetchAgeverificationData: (state, action) => {
      const { data, totalCount } = action.payload;
      state.error = null;
      state.ageVerificationData = data;
      state.totalCount = totalCount;
      state.listLoading = false;
    },
    fetchShipmentData: (state, action) => {
      const { data, totalCount } = action.payload;
      state.error = null;
      state.shipmentData = data;
      state.totalCount = totalCount;
      state.listLoading = false;
    },
    setReportShipmentsCount: (state, action) => {
      state.carrierCountSummary = action.payload?.shipmentCount?.carrierCountSummary;
      state.orderStatusCountSummary = action.payload?.shipmentCount?.orderStatusCountSummary;
      state.shipmentStatusSummary = action.payload?.shipmentCount?.shipmentStatusSummary;
    },
  },
});
