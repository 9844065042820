import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import dotenv from "dotenv";
import store, { persistor } from "./redux/store";
import { SplashScreenProvider } from "./app/Context/LayoutContext";
import { BoxcheckI18nProvider } from "./app/Context/i18n";
import * as _redux from "./redux";
import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-loading-skeleton/dist/skeleton.css"

dotenv.config();

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Inject metronic interceptors for axios.
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);

ReactDOM.render(
  <BoxcheckI18nProvider>
    <SplashScreenProvider>
      <App store={store} persistor={persistor} basename={PUBLIC_URL} />
    </SplashScreenProvider>
  </BoxcheckI18nProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
