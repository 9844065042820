import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { LayoutSplashScreen } from "../../../Context/LayoutContext";
import * as auth from "../_redux/authRedux";
import * as shipment from "../../Shipments/_redux/shipmentSlice";

const Logout = (props) => {
  const { hasAuthToken, logout } = props;
  const { actions } = shipment.shipmentsSlice;
  const dispatch = useDispatch();

  useEffect(() => {
    logout();
    dispatch(actions.saveSelectedShipment());
  });

  return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
};

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Logout);
