import React, { useCallback } from "react";
import CheckIcon from "@heroicons/react/solid/CheckIcon";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import HeaderNotificationList from "./HeaderNotificationList";
import { updateNotificationStatuses } from "../../../modules/Notification/_redux/notificationActions";
import Spinner from "../../Spinner";
import { Loader } from "../../Loader";

const HeaderNotification = ({ onClick }) => {
  const dispatch = useDispatch()
  const { notifications, listLoading, parentId, childrenId, actionLoading, user } = useSelector(({ notifications, auth }) => {
    return {
      notifications: notifications.notifications,
      listLoading: notifications.listLoading,
      actionLoading: notifications.actionLoading,
      parentId: auth.parentId,
      childrenId: auth.childrenId,
      user: auth.user
    };
  });
  const { ProfilePreferences } = user

  const markAsRead = () => {
    if (ProfilePreferences && ProfilePreferences?.enabledNotifications) {
      let data = { parentAccount: parentId, childAccount: childrenId };
      if ((notifications || [])?.filter((item) => item.message_read === false)?.length > 0) {
        const docIds = notifications?.filter(item => item.message_read === false)?.map(item => { return item._id })
        data.ids = docIds
        data.allNotifications = false
        dispatch(updateNotificationStatuses(data))
      }
    }
  };
  const updateNotificationStatus = useCallback(
    (data) => {
      if (ProfilePreferences && ProfilePreferences?.enabledNotifications) {
        data.allNotifications = false
        dispatch(updateNotificationStatuses(data))
      }
    },
    [notifications]
  );
  return (
    <div onClick={(e) => e.stopPropagation()} className="absolute md:-right-20 -right-10 sm:w-[536px] w-[95vw] py-4 md:top-10 top-10 space-y-2 text-gray-600 bg-white border border-gray-100 rounded-xl custom-shadow dark:text-gray-300 dark:border-gray-700 dark:bg-gray-700">
      <div className="flex justify-between px-4 notification-head pb-3">
        <p className="text-darkBlack text-sm font-medium">Notifications</p>
        <div className="flex text-primary gap-1">
          <CheckIcon className="h-5" />
          <p className="text-sm font-normal cursor-pointer" onClick={markAsRead}>
            {(notifications || [])?.filter((item) => item.message_read === false)?.length > 0 ? "Mark all as read" : "All read"}
            {actionLoading && <Loader className={false} />}
          </p>
        </div>
      </div>
      <ul className="mt-0">
        {listLoading && <Spinner />}
        {(notifications || [])?.map((item) => (
          <React.Fragment key={item._id}>
            <HeaderNotificationList item={item} updateNotificationStatus={updateNotificationStatus} />
          </React.Fragment>
        ))}
      </ul>
      <div className="px-4 pt-2" onClick={onClick}>
        <Link to="/notification" className="text-primary text-sm">
          View all notifications
        </Link>
      </div>
    </div>
  );
};
export default HeaderNotification;
