import * as requestFromServer from "./productsService";
import { productsSlice, callTypes } from "./productsSlice";
import { showResponseMessage } from "../../ToastMessage/_redux/toastAction";
const { actions } = productsSlice;

export const createAlcoholicProduct = (body, onSuccess, onError) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createAlcoholicProduct(body)
    .then((response) => {
      if (response.status === 201) {
        dispatch(
          showResponseMessage({
            message: "Create Product",
            messageText: response.data.message || "Alcoholic Product Created Successfully",
            messageType: "success",
            type: "Success",
          })
        );
        onSuccess();
      }
    })
    .catch((error) => {
      let errorMsg = "";
      error?.response?.data?.message?.forEach((err) => {
        errorMsg += err[[Object.keys(err)][0]] + "\n";
      });
      dispatch(actions.catchError({ error, callType: callTypes.action }));

      dispatch(
        showResponseMessage({
          message: "Can't Create Product",
          messageText: errorMsg || "Can't Create Product",
          messageType: "error",
          type: "Error",
        })
      );
      onError();
    });
};

export const updateAlcoholicProduct = (body, id, onSuccess, onError) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateAlcoholicProduct(body, id)
    .then((response) => {
      if (response.status === 200) {
        dispatch(
          showResponseMessage({
            message: "Update Product",
            messageText: response.data.message || "Alcoholic Product Updated Successfully",
            messageType: "success",
            type: "Success",
          })
        );
        onSuccess();
      }
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        showResponseMessage({
          message: "Can't Create Product",
          messageText: error?.response?.data?.message || "Can't Update Product",
          messageType: "error",
          type: "Error",
        })
      );
      onError();
    });
};

export const createBundleProducts = (body, onSuccess, onError) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createBundleProducts(body)
    .then((response) => {
      if (response.status === 201) {
        dispatch(
          showResponseMessage({
            message: "Product Created",
            messageText: response.data.message || "",
            messageType: "success",
            type: "Success",
          })
        );
        onSuccess();
      }
    })
    .catch((error) => {
      let errorMsg = "";
      error?.response?.data?.message?.forEach((err) => {
        errorMsg += err[[Object.keys(err)][0]] + "\n";
      });
      dispatch(actions.catchError({ error, callType: callTypes.action }));

      dispatch(
        showResponseMessage({
          message: "Can't Create Product",
          messageText: errorMsg || "Can't Create Product",
          messageType: "error",
          type: "Error",
        })
      );
      onError();
    });
};

export const updateBundleProducts = (body, id, onSuccess, onError) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateBundleProducts(body, id)
    .then((response) => {
      if (response.status === 200) {
        dispatch(
          showResponseMessage({
            message: "Product Updated",
            messageText: response.data.message || "",
            messageType: "success",
            type: "Success",
          })
        );
        onSuccess();
      }
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        showResponseMessage({
          message: "Can't Update Product",
          messageText: error?.response?.data?.message || "Can't Update Product",
          messageType: "error",
          type: "Error",
        })
      );
      onError();
    });
};

export const createNonAlcoholicProduct = (body, onSuccess, onError) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createNonAlcoholicProduct(body)
    .then((response) => {
      if (response.status === 201) {
        dispatch(
          showResponseMessage({
            message: "Create Product",
            messageText: response.data.message || "Non Alcoholic Product Created Successfully",
            messageType: "success",
            type: "Success",
          })
        );
        onSuccess();
      }
    })
    .catch((error) => {
      let errorMsg = "";
      error?.response?.data?.message?.forEach((err) => {
        errorMsg += err[[Object.keys(err)][0]] + "\n";
      });
      dispatch(actions.catchError({ error, callType: callTypes.action }));

      dispatch(
        showResponseMessage({
          message: "Can't Create Product",
          messageText: errorMsg || "Can't Create Product",
          messageType: "error",
          type: "Error",
        })
      );

      onError();
    });
};

export const updateNonAlcoholicProduct = (body, id, onSuccess, onError) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateNonAlcoholicProduct(body, id)
    .then((response) => {
      if (response.status === 200) {
        dispatch(
          showResponseMessage({
            message: "Update Product",
            messageText: response.data.message || "Non Alcoholic Product Updated Successfully",
            messageType: "success",
            type: "Success",
          })
        );
        onSuccess();
      }
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        showResponseMessage({
          message: "Can't Create Product",
          messageText: error?.response?.data?.message || "Can't Update Product",
          messageType: "error",
          type: "Error",
        })
      );
      onError();
    });
};

export const searchProductByParams = (searchParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .searchProductByParams(searchParams)
    .then((response) => {
      if (response.data) {
        dispatch(actions.setProducts({ data: response?.data?.data }, { callType: callTypes.list }));
        // if (response?.data?.data?.length === 0) {
        //   dispatch(
        //     showResponseMessage({
        //       message: "Products",
        //       messageText: response?.data?.message || "Product Not Found",
        //       messageType: "success",
        //       type: "Success",
        //     })
        //   );
        // }
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't Get Products";
      dispatch(actions.setProducts({ data: [] }, { callType: callTypes.list }));
      dispatch(
        showResponseMessage({
          message: "Can't Get Products",
          messageText: error?.response?.data?.message || "Product Not Found",
          messageType: "error",
          type: "Error",
        })
      );
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getProducts = (parentId, childrenId, itemsPerPage, pageNumber, sortKey, sortOrder, isArchive) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  const query = `parentAccount=${parentId}&childAccount=${childrenId}&withArchive=${isArchive}&page=${pageNumber}&perPage=${itemsPerPage}&sortKey=${sortKey}&order=${sortOrder}`;
  return requestFromServer
    .getProducts(query)
    .then((response) => {
      if (response.data) {
        dispatch(actions.setProducts({ data: response?.data?.data }, { callType: callTypes.list }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't Get Products";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getProductById = (productId, onError) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getProductById(productId)
    .then((response) => {
      if (response.data?.statusCode === 200) {
        dispatch(actions.setSelectedProduct({ data: response?.data?.data }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't Get productById";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        showResponseMessage({
          message: "Product not Found",
          messageText: error?.response?.data?.message || "Product not Found",
          messageType: "error",
          type: "Error",
        })
      );
      onError();
    });
};

export const unArchiveProducts = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .activeProduct(id)
    .then((response) => {
      if (response?.data?.statusCode === 200) {
        dispatch(
          showResponseMessage({
            message: "Product Active",
            messageText: response?.data?.message || "Product Active Successfully",
            messageType: "success",
            type: "Success",
            loadAPI: true,
          })
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't Get productById";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        showResponseMessage({
          message: "Product Not Active",
          messageText: error?.response?.data?.message || "Product Not Active Successfully",
          messageType: "error",
          type: "Error",
        })
      );
    });
};

export const toggleBulkUploadFields = (status) => (dispatch) => {
  dispatch(actions.toggleUploadProductFields({ status: status }));
};
/** Bulk Upload Products */
export const nonWinBulkProduct = (product, onSuccess, onError) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .nonWinBulkProduct(product)
    .then((response) => {
      if (response?.data?.statusCode === 200) {
        dispatch(
          showResponseMessage({
            message: "Product File uploaded Successfully",
            messageText:
              response?.data?.message || "Product File uploaded Successfully.If something went wrong while uploading data, you will receive an email with error messages.",
            messageType: "success",
            type: "Success",
          })
        );
        onSuccess();
      }
    })
    .catch((error) => {
      onError();
      error.clientMessage = "Product File not uploaded Successfully";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      const errorMessages = error?.response?.data?.message || [];
      if (typeof error?.response?.data?.message === "string") {
        dispatch(
          showResponseMessage({
            message: "Product File not uploaded Successfully",
            messageText: error?.response?.data?.message || "Product Not Active Successfully",
            messageType: "error",
            type: "Error",
          })
        );
      } else {
        const message = (errorMessages || []).map((item) => {
          return Object.values(item)[0];
        });
        dispatch(
          showResponseMessage({
            message: "Product File not uploaded Successfully",
            messageText: message || "Product Not Active Successfully",
            messageType: "error",
            type: "Error",
          })
        );
      }
    });
};
export const kitGiftBulkProduct = (product, onSuccess, onError) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .kitGiftBulkProduct(product)
    .then((response) => {
      if (response?.data?.statusCode === 200) {
        dispatch(
          showResponseMessage({
            message: "Product File uploaded Successfully",
            messageText:
              response?.data?.message || "Product File uploaded Successfully.If something went wrong while uploading data, you will receive an email with error messages.",
            messageType: "success",
            type: "Success",
          })
        );
        onSuccess();
      }
    })
    .catch((error) => {
      onError();
      error.clientMessage = "Product File not uploaded Successfully";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      const errorMessages = error?.response?.data?.message || [];
      if (typeof error?.response?.data?.message === "string") {
        dispatch(
          showResponseMessage({
            message: "Product File not uploaded Successfully",
            messageText: error?.response?.data?.message || "Product Not Active Successfully",
            messageType: "error",
            type: "Error",
          })
        );
      } else {
        const message = (errorMessages || []).map((item) => {
          return Object.values(item)[0];
        });
        dispatch(
          showResponseMessage({
            message: "Product File not uploaded Successfully",
            messageText: message || "Product Not Active Successfully",
            messageType: "error",
            type: "Error",
          })
        );
      }
    });
};
export const wineBulkProduct = (product, onSuccess, onError) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .wineBulkProduct(product)
    .then((response) => {
      if (response?.data?.statusCode === 200) {
        dispatch(
          showResponseMessage({
            message: "Product File uploaded Successfully",
            messageText:
              response?.data?.message || "Product File uploaded Successfully.If something went wrong while uploading data, you will receive an email with error messages.",
            messageType: "success",
            type: "Success",
          })
        );
        onSuccess();
      }
    })
    .catch((error) => {
      onError();
      error.clientMessage = "Product File not uploaded Successfully";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      const errorMessages = error?.response?.data?.message || [];
      if (typeof error?.response?.data?.message === "string") {
        dispatch(
          showResponseMessage({
            message: "Product File not uploaded Successfully",
            messageText: error?.response?.data?.message || "Product Not Active Successfully",
            messageType: "error",
            type: "Error",
          })
        );
      } else {
        const message = (errorMessages || []).map((item) => {
          return Object.values(item)[0];
        });
        dispatch(
          showResponseMessage({
            message: "Product File not uploaded Successfully",
            messageText: message || "Product Not Active Successfully",
            messageType: "error",
            type: "Error",
          })
        );
      }
    });
};
